:root {
}

select {
  padding: var(--input-padding, 0.5em 1em);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 3px;
  min-width: 100px;
}

.addItem {
  margin: 1em;
  padding: 0.8em;
  background-color: var(--item-color, rgb(250, 250, 250));
  box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.3);
  border-radius: 3px;
  flex: 0 1 var(--item-tile-size, 150px);
  justify-content: center;
  align-items: center;
  display: flex;
  height: var(--item-tile-size, 150px);
  color: var(--text-color, rgb(80, 80, 80));
  border: 3px solid transparent;
  transition: all ease 100ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all ease 100ms;
  }
  --item-color: transparent;
  .fas {
    font-size: 2em;
    color: var(--text-color, #28C0D7);
  }
  border: 3px dashed var(--text-color, #28C0D7);
}

.item-active {
  --item-color: #28C0D7;
  position: relative;
  &:before {
    background-color: var(--item-color);
    font-size: 0.7em;
    content: "Active";
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 0.25em 0.5em;
    border-radius: 25px;
  }
}

.item-inactive {
  --item-color: rgb(179, 179, 179);
  position: relative;
  font-style: italic;
  font-weight: normal;
  &:before {
    background-color: var(--item-color);
    font-size: 0.7em;
    content: "Inactive";
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 0.25em 0.5em;
    border-radius: 25px;
  }
}

i.round {
  margin: 0 0.5em;
  cursor: pointer;
  background-color: var(--icon-color, var(--secondary-color));
  color: var(--icon-font-color, white);
  width: var(--icon-size, 24px);
  height: var(--icon-size, 24px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 0.9em;
}

.move {
  cursor: grab;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

i.transparent {
  --secondary-color:transparent;
  color:gray !important;
}


/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #EAEAEA;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--secondary-color);
}
::-webkit-scrollbar-thumb:window-inactive {
background: var(--secondary-color);
}
