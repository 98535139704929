label:not(.switch) {
    font-family: var(--main-font);
    color: var(--label-color);
    font-weight: bold;
    font-size:0.9em;
    display: flex;
    margin-top: 0px;
    text-align: center;
}
.title {
    display: flex;
    transform: translateX(86);
    margin-bottom: 30px;
    padding: 30px;
    z-index: 0;
    align-items: center;
  }

input[type="text"],
textarea[type="text"],
input[type="password"] {
    border: 1px solid var(--border-color);
    padding: var(--input-padding, 0.5em 1em);
    margin: var(--input-margin, 0.5em 0.5em);
}
