@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
:root {
    --primary-color: #e01a22;
    --secondary-color: #28C0D7;
    --secondary-color-lighter: #a8c4c8;

    --border-color: black;
    --background-color: #F5F5F5;;
    --widget-background-color:rgb(240,240,240);
    --label-color: rgb(127, 127, 127);

    --main-font: "Poppins";
}
