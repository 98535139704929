button {
    --button-text-color: rgb(255, 255, 255);
    background-color: var(--button-color, var(--secondary-color));
    border: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: var(--button-text-color);
    font-weight: 800;
    transition: all ease 200ms;
    border:1px solid var(--button-border-color, transparent);
    &:hover:not([disabled=true]) {
        transition: all ease 200ms;
        background-color: var(--button-color-lighter, var(--secondary-color-lighter));
    }
    &.disabled,
    &[disabled=true]
     {
      --button-color:rgb(169, 169, 169);
      --button-text-color:rgb(102, 102, 102);
      --button-border-color:transparent;
    }
}
