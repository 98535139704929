/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
            opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
            opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
}

@-webkit-keyframes scale-up-center-reverse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
            opacity: 0;
  }
}
@keyframes scale-up-center-reverse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
            opacity: 0;
  }
}

@-webkit-keyframes progressive-opacity {
  0% {
            opacity: 0;
  }
  100% {
            opacity: 1;
  }
}
@keyframes progressive-opacity {
  0% {
            opacity: 0;
  }
  100% {
            opacity: 1;
  }
}

@-webkit-keyframes progressive-opacity-reverse {
  0% {
            opacity: 1;
  }
  100% {
            opacity: 0;
  }
}
@keyframes progressive-opacity-reverse {
  0% {
            opacity: 1;
  }
  100% {
            opacity: 0;
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 13:48:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 13:49:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
