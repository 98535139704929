input,
textarea {
  &[type="text"]{
    &.overedit {
      background-color: var(--input-hover-color, transparent);
      --border-color:rgb(240, 240, 240);
      transition: background-color ease 100ms;
      border-radius: var(--widget-border-radius, 3px);
      //flex: 0 0 30px;
      &:hover {
        transition: background-color ease 100ms;
        background-color: var(--input-hover-color, rgb(230, 230, 230));
        & + i.fa-pen {
          --pen-alpha:.8;
        }
      }
      &:focus,
      &:active {
        --input-hover-color: rgb(255, 255, 255);
      }
      & + i.fa-pen {
        content: "\f304";
        margin-right: 1em;
        color: #28C0D7;
      }
    }
  }
  &[type="number"] {
    &.overedit {
      background-color: var(--input-hover-color, transparent);
      --border-color:rgb(240, 240, 240);
      transition: background-color ease 100ms;
      border-radius: var(--widget-border-radius, 3px);
      &:hover {
        transition: background-color ease 100ms;
        background-color: var(--input-hover-color, rgb(230, 230, 230));
        & + i.fa-pen {
          --pen-alpha:.8;
        }
      }
      &:focus,
      &:active {
        --input-hover-color: rgb(255, 255, 255);
      }
      & + i.fa-pen {
        content: "\f304";
        margin-left:-2em;
        margin-right: 1em;
        color: var(--pen-color, rgba(var(--pen-color-value), var(--pen-alpha, 0.6)));
      }
    }
  }
}
textarea{
  &[type="text"]{
    &.overedit {
      max-width:500px;
      min-width: 200px;
      max-height: 100px;
      min-height: 36px;
      font-family: inherit;
    }
  }
}