/* You can add global styles to this file, and also import other style files */

@import "styles/reset";
@import "styles/root";
@import "styles/fonts";
@import "styles/fontawesome-scss/fontawesome";
@import "styles/fontawesome-scss/regular";
@import "styles/fontawesome-scss/solid";
@import "styles/base";
@import "styles/layout";
@import "styles/inputs";
@import "styles/buttons";
@import "styles/forms";
@import "styles/animations";
@import "styles/common";
@import "styles/switch";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "SF_PRO_DISPLAY";
  src: url('./assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF') format("opentype");
}

html {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

*:focus {
  outline: none;
}

.required {
  &::after {
    content:'*';
    color:var(--error-color, red);
    font-variant-position: super;
    margin:0 .25em;
  }
}

body {
  display: flex;
  flex: 1 1 auto;
  background-color: var(--background-color);
  font-family: var(--main-font);
  height: 100%;
  width: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.card{
  display: inline-table;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(11, 33, 57, 0.11);
  border-radius: 18px;
}

b{
  font-weight: bold;
}